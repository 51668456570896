<script setup lang="ts">
import { LineItem } from "@shopware-pwa/types";
import {computed} from "vue";
const { cartItems, removeItem, subtotal, totalPrice, shippingTotal } = useCart();
const { codeErrorsNotification } = useCartNotification();
const { selectedPaymentMethod } = useSessionContext();

const props = withDefaults(
  defineProps<{
    order?: any;
    showPromotionCode?: boolean;
  }>(),
  {
    order: null,
    showPromotionCode: true,
  },
);

const removeItemHandler = (appliedPromotionCode: LineItem) => {
  removeItem(appliedPromotionCode);
  codeErrorsNotification();
};

const appliedPromotionCodes = computed(() => {
  return cartItems.value.filter((item: string) => item.type === 'nav-voucher' || item.type === 'promotion');
});

/**
 * in checkout/summary/[id] the appliedPromotionCodes from useCart() is emtpy. To show applied promotion codes we have
 * to get them from the order. Order should only be available on checkout/summary/[id].
 */
const appliedPromotionCodesFromOrder = computed(() => {
  const lineItems = props.order?.lineItems || []

  try {
    return lineItems.filter((item: string) => item.type === 'nav-voucher' || item.type === 'promotion');
  } catch(e){
    // fail silently
  }

  return []
})

const showAppliedPromotionCodesFromCart = computed(() => {
  return appliedPromotionCodes.value.length > 0;
});

const subtotalWithoutPromotionCodes = computed(() => {
  const promotionCodesFromCart  = appliedPromotionCodes.value.map((el) => el.price?.totalPrice ?? 0).reduce((partialSum, a) => partialSum + a, 0);
  // maybe its necessary to substract the applied promotion codes from order when cart is not available on checkout/summary/[id]
  const promotionCodesFromOrder = 0; // appliedPromotionCodesFromOrder.value.map((el) => el.totalPrice ?? 0).reduce((partialSum, a) => partialSum + a, 0)

  return (
    (props.order?.positionPrice ?? subtotal.value) -
    promotionCodesFromCart -
    promotionCodesFromOrder
  );
});

const paymentMethod = ref(selectedPaymentMethod.value.name);

watch(selectedPaymentMethod, () => {
  if(paymentMethod.value !== selectedPaymentMethod.value.name) {
    paymentMethod.value = selectedPaymentMethod.value.name;

    if(paymentMethod.value == "Bezahlung vor Ort") {
      appliedPromotionCodes.value.forEach((appliedPromotionCode) => {
        removeItem(appliedPromotionCode);
      });
    }
  }
});
</script>

<template>
  <div class="py-4" data-testid="checkoutcartsummary">

    <div v-if="showPromotionCode && selectedPaymentMethod.name !== 'Bezahlung vor Ort'" class="mb-4 justify-between">
      <CheckoutPromotionCode />
    </div>

    <div v-if="appliedPromotionCodesFromOrder" class="py-1 text-small">
      <!-- loop over applied promotion code in order (which cannot be removed) -->
      <template v-for="appliedPromotionCode in appliedPromotionCodesFromOrder" :key="appliedPromotionCode.id">
        <div class="flex justify-between">
          <p class="flex items-end gap-1">
            <b class="block">{{ appliedPromotionCode.referencedId }}</b>&nbsp;
          </p>
          <SharedPrice :value="appliedPromotionCode.totalPrice" class="text-maas-typography-navigation-red" />
        </div>
      </template>
    </div>

    <div class="flex justify-between py-1 text-small ">
      <p>Zwischensumme</p>
      <SharedPrice :value="subtotalWithoutPromotionCodes" data-testid="cart-subtotalWithoutPromotionCodes" />
    </div>

    <div v-if="showAppliedPromotionCodesFromCart" class="py-1 text-small">
      <!-- loop over applied promotion code in cart (which can be removed) -->
      <template v-for="appliedPromotionCode in appliedPromotionCodes" :key="appliedPromotionCode.id">
        <div class="flex justify-between">
          <p class="flex items-baseline gap-1">
            <NuxtLink
              v-if="showPromotionCode"
              class="block mr-2 cursor-pointer"
              @click="removeItemHandler(appliedPromotionCode)"
            >
              <svg class="h-3 w-3" height="1000" viewBox="0 0 875 1000" width="875" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 281.296l0 -68.355q1.953 -37.107 29.295 -62.496t64.449 -25.389l93.744 0l0 -31.248q0 -39.06 27.342 -66.402t66.402 -27.342l312.48 0q39.06 0 66.402 27.342t27.342 66.402l0 31.248l93.744 0q37.107 0 64.449 25.389t29.295 62.496l0 68.355q0 25.389 -18.553 43.943t-43.943 18.553l0 531.216q0 52.731 -36.13 88.862t-88.862 36.13l-499.968 0q-52.731 0 -88.862 -36.13t-36.13 -88.862l0 -531.216q-25.389 0 -43.943 -18.553t-18.553 -43.943zm62.496 0l749.952 0l0 -62.496q0 -13.671 -8.789 -22.46t-22.46 -8.789l-687.456 0q-13.671 0 -22.46 8.789t-8.789 22.46l0 62.496zm62.496 593.712q0 25.389 18.553 43.943t43.943 18.553l499.968 0q25.389 0 43.943 -18.553t18.553 -43.943l0 -531.216l-624.96 0l0 531.216zm62.496 -31.248l0 -406.224q0 -13.671 8.789 -22.46t22.46 -8.789l62.496 0q13.671 0 22.46 8.789t8.789 22.46l0 406.224q0 13.671 -8.789 22.46t-22.46 8.789l-62.496 0q-13.671 0 -22.46 -8.789t-8.789 -22.46zm31.248 0l62.496 0l0 -406.224l-62.496 0l0 406.224zm31.248 -718.704l374.976 0l0 -31.248q0 -13.671 -8.789 -22.46t-22.46 -8.789l-312.48 0q-13.671 0 -22.46 8.789t-8.789 22.46l0 31.248zm124.992 718.704l0 -406.224q0 -13.671 8.789 -22.46t22.46 -8.789l62.496 0q13.671 0 22.46 8.789t8.789 22.46l0 406.224q0 13.671 -8.789 22.46t-22.46 8.789l-62.496 0q-13.671 0 -22.46 -8.789t-8.789 -22.46zm31.248 0l62.496 0l0 -406.224l-62.496 0l0 406.224zm156.24 0l0 -406.224q0 -13.671 8.789 -22.46t22.46 -8.789l62.496 0q13.671 0 22.46 8.789t8.789 22.46l0 406.224q0 13.671 -8.789 22.46t-22.46 8.789l-62.496 0q-13.671 0 -22.46 -8.789t-8.789 -22.46zm31.248 0l62.496 0l0 -406.224l-62.496 0l0 406.224z"
                />
              </svg>
            </NuxtLink>
            <b class="block">{{ appliedPromotionCode.referencedId }}</b>&nbsp;
          </p>
          <SharedPrice :value="appliedPromotionCode.price?.totalPrice" class="text-maas-typography-navigation-red" />
        </div>
      </template>
    </div>

    <div class="flex justify-between py-1 text-small">
      <p>Versandkosten</p>
      <SharedPrice :value="order?.shippingTotal ?? shippingTotal" data-testid="cart-shippingTotal" />
    </div>

    <div class="flex justify-between pt-4 text-p font-bold">
      <p>Gesamtsumme <span class="text-small">inkl. MwSt.</span></p>
      <SharedPrice :value="order?.price?.totalPrice ?? totalPrice" data-testid="cart-totalPrice" />
    </div>
  </div>
</template>
